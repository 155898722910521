<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row>
        <v-col cols="3">
          <BackofficeNavigation class="sticky-block"/>
        </v-col>

        <v-col cols="9">

          <div v-if="isLoading">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="article,actions"/>
              </v-card-text>
            </v-card>
            <v-card class="rounded-lg shadow mt-5">
              <v-card-text>
                <v-skeleton-loader type="table"/>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>

            <v-card class="rounded-lg shadow">

              <v-card-title>
                {{ $func.setName(analytic.investigation.name, analytic.investigation.campaign.lang) }}
                <v-spacer/>
                <v-btn color="primary"
                       depressed
                       @click="$router.push('/backoffice/completed')">
                  <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                  {{ $t('btn.back') }}
                </v-btn>
              </v-card-title>

              <v-card-subtitle>
                CAMPAIGNE :
                {{ $func.setName(analytic.investigation.campaign.name, analytic.investigation.campaign.lang) }}
                <v-spacer class="py-1"/>
                <strong class="blue--text">
                  MONTANT :
                  {{ analytic.investigation.amount | toCurrency }}
                </strong>
                <strong class="blue--text ml-5">
                  MONTANT TOTAL :
                  {{ (analytic.investigation.amount * analytic.totalValidated)| toCurrency }}
                </strong>

              </v-card-subtitle>


              <v-card-text>


                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="blue">mdi-clipboard-text-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ analytic.total }}</v-list-item-title>
                    <v-list-item-subtitle>Total</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider/>

                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Réponses validées</v-list-item-title>
                    <v-list-item-subtitle>Total : {{ analytic.totalValidated }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-right">
                      <strong>{{ analytic.percentValidated }}%</strong>
                      <v-progress-linear
                          rounded
                          color="blue"
                          height="5"
                          :value="analytic.percentValidated"
                          class="w-150"
                          striped
                      ></v-progress-linear>
                    </div>
                  </v-list-item-action>
                </v-list-item>

                <v-divider/>

                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Réponses réfusées</v-list-item-title>
                    <v-list-item-subtitle>Total : {{ analytic.totalRefused }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-right">
                      <strong>{{ analytic.percentRefused }}%</strong>
                      <v-progress-linear
                          rounded
                          color="blue"
                          height="5"
                          :value="analytic.percentRefused"
                          class="w-150"
                          striped
                      ></v-progress-linear>
                    </div>
                  </v-list-item-action>
                </v-list-item>


              </v-card-text>
            </v-card>

            <v-card class="rounded-lg shadow mt-5">

              <v-card-title>

                <v-spacer/>

                <Refresh @refresh="showCompletedInvestigations"/>

                <v-btn color="primary" outlined>
                  <v-icon left>mdi-filter</v-icon>
                  Filtrage
                </v-btn>

              </v-card-title>

              <v-divider/>

              <div v-if="tasks.length">

                <v-card-text class="pa-0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Point de vente</th>
                        <th>Date sync</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,i) in tasks" :key="i">

                        <td>
                       <span class="d-flex text-no-wrap cursor-pointer"
                             @click="showSalepoint(item.salepoint)">
                         {{ item.salepoint.name }}
                         <v-icon color="blue" small right>mdi-plus</v-icon>
                       </span>
                        </td>

                        <td>
                          {{ item.syncronized_at }}
                        </td>

                        <td>
                          <v-chip small :color="item.status === 'validated' ? 'success': 'error'">
                            {{ item.status === 'validated' ? 'Validée' : 'Réfusée' }}
                          </v-chip>
                        </td>

                        <td>
                          <v-btn color="blue" icon
                                 @click="showResponse(item)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-card-text>

                <v-divider v-if="total > $totalPerPage"/>

                <v-card-actions v-if="total > $totalPerPage">
                  <div class="w-100">
                    <v-select dense filled
                              hide-details
                              :items="$perPage"
                              v-model="perPage"></v-select>
                  </div>
                  <v-spacer/>
                  <v-pagination total-visible="10"
                                circle
                                v-model="pagination.current"
                                :length="pagination.total"
                                @input="showCompletedInvestigations"></v-pagination>
                </v-card-actions>

              </div>

              <v-card-text v-else>
                <NoResult size="200"/>
              </v-card-text>

            </v-card>
          </div>


        </v-col>

      </v-row>


    </v-container>

    <SalepointDialog ref="salepointDialog"/>

    <ResponsesDialog :with-action="false"
                     ref="responsesDialog"/>

  </div>
</template>

<script>

import BackofficeNavigation from "@/views/backoffice/components/BackofficeNavigation";
import ResponsesDialog from "@/views/backoffice/components/ResponsesDialog";
import {HTTP} from "@/http-common";

export default {
  components: {BackofficeNavigation, ResponsesDialog},
  data() {
    return {
      isLoading: false,
      tasks: [],
      analytic: null,
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
    }
  },
  methods: {
    showCompletedInvestigations() {
      this.$Progress.start()
      this.isLoading = true
      const id = this.$route.params.id
      HTTP.get('/backoffice/investigations/' + id + '/completed/show?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.items.current_page;
        this.pagination.total = res.data.data.items.last_page;
        this.total = res.data.data.items.total;

        this.tasks = res.data.data.items.data
        this.analytic = res.data.data.analytic
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    showSalepoint(data) {
      this.$refs.salepointDialog.open(data)
    },
    showResponse(data) {
      this.$refs.responsesDialog.open(data)
    },

  },
  created() {
    this.showCompletedInvestigations()
  },
  watch: {
    perPage() {
      this.showCompletedInvestigations()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/#', text: 'breadcrumb.backoffice', disabled: true},
        {to: '/#', text: 'backoffice.finalValidation', disabled: true},
      ]
    }
  }
}
</script>

<style scoped>
</style>